import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../styling/ForFitnessProf.scss";
import ActiveCampaignForm from "../components/ActiveCampaignForms/ActiveCampaignForm";

import weights from "../assets/forFitnessPage/weights.png";
import desk1 from "../assets/forFitnessPage/desk1.png";

import marketing from "../assets/forFitnessPage/marketing.png";

import Resources from "../components/Resources";

const ForFitnessProf =()=>{
    const location = useLocation();
    const elementRef = useRef(null);

    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(
        function () {
            if (location.pathname === "/forfitnessprofessionals") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setHasLoaded(true)
        },
        [location]);

    return (
        <>
            <div className="forFitnessProfPage" ref={elementRef}>
                <header className="forFitnessHeader">
                    <div className="wrapper">
                        <NavBar />
                    </div>

                    <div className="fitnessHeaderContent">
                        <div className="wrapper">
                            <div className="forFitnessHeader">
                                <h1>Grow Your Fitness Business</h1>
                                <p>
                                    We help fitness trainers in Toronto by
                                    providing them a space to run their personal
                                    training business out of.
                                </p>
                                <div className="buttonContainer">
                                    <button>
                                        <Link to="/discover">
                                            Become a pltfrmPro
                                        </Link>
                                    </button>

                                    <button>
                                        <Link to="/form">Contact Us</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="beYourOwnBoss">
                    <div className="wrapper">
                        <div className="bossTitle">
                            <h2>Be Your Own Boss</h2>
                            <div className="bossTitleImportant">
                                <p className="headerImportant">with</p>
                                <div className="logoName"></div>
                            </div>
                        </div>

                        <ul className="fitnessImgContainer">
                            <li>
                                <img
                                    src={weights}
                                    alt="a rack filled with dumbbell weights"
                                />
                                <p>
                                    Access to a Fully Equipt Gym Dedicated to
                                    1:1 Personal Training Clients
                                </p>
                            </li>
                            <li>
                                <img
                                    src={desk1}
                                    alt="Desk with a computer and a cup of coffee"
                                />

                                <p>
                                    An Array of Workspaces, Lounges, and Intake
                                    Rooms
                                </p>
                            </li>
                            <li>
                                <img
                                    src={marketing}
                                    alt="white board with marketing strategy written in fun colours"
                                />

                                <p>
                                    Dedicated Attention and Services to Help
                                    Grow Your Business
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="signUpToday">
                    <div className="wrapper">
                        <h2 className="fitnessSectionTitle">
                            Become a
                            <span className="importantHeader"> PLTFRMPRO</span>{" "}
                            today
                        </h2>
                        <p>
                            When you sign up you will still be an independent.
                            You will simply get access to our rates and be able
                            to book spaces and time slots to use PLTFRM's
                            facilities.
                        </p>

                        <ActiveCampaignForm form={hasLoaded} />

                        <p className="disclaimerText">
                            When you sign up today you will get first priority
                            at our *SPECIAL* introductory rates.
                        </p>
                    </div>
                </section>
                
                <Resources/>

                <section className="areYouReadySection">
                    <div className="wrapper">
                        <h2>Are you ready to grow your business?</h2>
                        <div className="areYouReadyContainer">
                            <div className="areYouReadyText">
                                <div className="logoName"></div>
                                <p>is here to help you do that!</p>
                            </div>

                            <button>
                                <Link to="/form">Become a PLTFRMPRO</Link>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ForFitnessProf;