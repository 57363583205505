import React from 'react'
import '../components/NavBar'
import NavBar from '../components/NavBar'
import "../styling/ClassesPage.scss"
import WellnessLiving from '../components/WellnessLivingWidget/WellnessLiving'
import GroupClassesList from '../components/GroupClassesList'
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

function ClassesPage() {
     const location = useLocation();
     const elementRef = useRef(null);

     const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(
        function () {
            if (location.pathname === "/classes") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setHasLoaded(true);
        },
        [location]
    );
  return (
      <div className="pltfrmClasses" ref={elementRef}>
          <header className="classesHeader">
              <div className="wrapper">
                  <NavBar />
              </div>
              <div className="classesHeaderContent">
                  <div className="wrapper">
                      <div className="classesHeaderText">
                          <h1>Group Classes</h1>
                      </div>
                  </div>
              </div>
          </header>

          <section>
              <div className="wrapper">
                  <GroupClassesList />
              </div>
          </section>

          <section className="wellnessLiving">
              <div className="wrapper">
                  <WellnessLiving load={hasLoaded} />
              </div>
          </section>

          <section className="classesMembershipSection">
              <div className="wrapper">
                  <div className="classMembership">
                      <h2>
                          You get free access to group classes on our monthly
                          membership
                      </h2>
                      <p>individual class is $27 + HST</p>
                  </div>
                  <div className="membershipOptionList">
                      <div className="optionInfo">
                          <div className="membershipName">
                              <h4>5 CLASS PASS</h4>
                              <p>Valid for 3 months</p>
                          </div>
                          <div className="membershipCost">
                              <h3>$70</h3>
                              <p>per month + tax</p>
                          </div>
                          <button>
                              <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=1&k_id=3446339">
                                  Buy Now
                              </a>
                          </button>
                      </div>
                      <div className="optionInfo">
                          <div className="membershipName">
                              <h4>10 CLASS PASS</h4>
                              <p>Valid for 4 months</p>
                          </div>
                          <div className="membershipCost">
                              <h3>$100</h3>
                              <p>per month + tax</p>
                          </div>
                          <button>
                              <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=1&k_id=3446344">
                                  Buy Now
                              </a>
                          </button>
                      </div>
                      <div className="optionInfo">
                          <div className="membershipName">
                              <h4>MONTHLY UNLIMITED GROUP CLASS PASS</h4>
                              <p>Renews Monthly</p>
                          </div>
                          <div className="membershipCost">
                              <h3>$60</h3>
                              <p>per month + tax</p>
                          </div>
                          <button>
                              <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=1&k_id=3446175">
                                  Buy Now
                              </a>
                          </button>
                      </div>
                  </div>
              </div>
          </section>

          <section className="membershipTrialSection">
              <div className="wrapper">
                  <h2>
                      WANT TO EXPERIENCE OUR GYM & CLASSES BEFORE COMMITTING?
                  </h2>
                  <p>
                      Get unlimited access to our gym space + group classes for
                      one week for $37 (Available to first-time members only)
                  </p>
                  <button>
                      <a href="https://www.wellnessliving.com/rs/catalog-view.html?k_business=416410&id_sale=5&k_id=3446322">
                          Buy Now
                      </a>
                  </button>
              </div>
          </section>
      </div>
  );
}

export default ClassesPage