import React from 'react';
import Form from '../components/Form';
import NavBar from "../components/NavBar";

import { Link as Scroll } from "react-scroll";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

//Hero Image
import video from "../assets/heroImages/pltfrmHero.mp4";

//Graphics
import weight from "../assets/graphics/PLTFRM_Graphic1_weight.png"
import camera from "../assets/graphics/PLTFRM_Graphic2_camera.png";
import showerhead from "../assets/graphics/PLTFRM_Graphic3_showerhead.png";
import laptop from "../assets/graphics/PLTFRM_Graphic4_laptop.png";
import wifi from "../assets/graphics/PLTFRM_Graphic5_wifi.png";
import onlineBooking from "../assets/graphics/PLTFRM_Graphic6_onlinebooking.png";
import people from "../assets/graphics/PLTFRM_Graphic7.png";
import clock from "../assets/graphics/PLTFRM_Graphic8_clock.png";

//FacilityCarousel Images
import image1 from "../assets/facilityImages/PLTFRM_ourfacility_1.png"
import image2 from "../assets/facilityImages/PLTFRM_ourfacility_2.png";
import image3 from "../assets/facilityImages/PLTFRM_ourfacility_3.png";
import image4 from "../assets/facilityImages/PLTFRM_ourfacility_4.png";
import image5 from "../assets/facilityImages/PLTFRM_ourfacility_5.png";
import image6 from "../assets/facilityImages/PLTFRM_ourfacility_6.png";

//Styles
import "../styling/Header.scss";
import "../styling/HomePage.scss"

const HomePage = ()=>{
    const images =[image1, image2, image3, image4, image5, image6]
    
    function previousImage(){
        let slider = document.getElementById("slider")
        slider.scrollLeft = slider.scrollLeft - 400;
    }

    function nextImage() {
        let slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 400;
    }

    return (
        <>
            <header className="mainHeader">
                <video
                    src={video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    type="video/mp4"
                    id="myVideo"
                ></video>
                <div className="wrapper">
                    <NavBar />

                    <div className="title">
                        <h1>
                            Toronto's Premier Private Training Gym & Coworking
                            Space
                        </h1>
                        <p>
                            Bringing you a premium space to sweat, work, relax,
                            & connect
                        </p>
                    </div>

                    <div className="arrowContainer">
                        <div className="arrowContainerText">
                            <p>Discover how</p>
                            <div className="logoName"></div>
                            <p>works</p>
                        </div>
                        <div className="arrowHoverContainer">
                            <Scroll
                                to="discover"
                                smooth={true}
                                className="arrowIcon"
                            >
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Scroll>
                        </div>
                    </div>
                </div>
            </header>
            <section className="specialsSection">
                <div className="wrapper">
                    <h3 className="importantText">Trial pass</h3>
                    <h2>7 Days for $37</h2>
                    <button>
                        <a href="https://www.wellnessliving.com/login/pltfrm">
                            Join PLTFRM
                        </a>
                    </button>
                </div>
            </section>

            <section className="carouselSection">
                <div className="wrapper">
                    <h2>Our Facility</h2>
                    <div className="facilityCarousel">
                        <button
                            className="carouselButton buttonPrevious"
                            onClick={previousImage}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <div className="carouselImage" id="slider">
                            {images.map((image, index) => (
                                <img
                                    src={image}
                                    alt=""
                                    key={index}
                                    className={
                                        index === images.length - 1
                                            ? "slide lastSlide"
                                            : "slide"
                                    }
                                />
                            ))}
                        </div>
                        <button
                            className="carouselButton buttonNext"
                            onClick={nextImage}
                        >
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>
            </section>

            <section className="aboutSection" id="about" name="discover">
                <div className="wrapper">
                    <div className="aboutHeading">
                        <div className="logoName"></div>
                        <h3>a 24/7 space dedicated to wellness</h3>
                    </div>
                    <ul>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                We offer weekly boutique style group classes
                                focused on building community through fitness.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                Book your private workout alcove to guarantee a
                                dedicated space and equipment to get the most
                                from your workouts.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                The best rate for personal trainers interested
                                in running their business with full autonomy.
                            </p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>One space to sweat, work, relax, and connect.</p>
                        </li>
                        <li>
                            <div className="shortLogo"></div>
                            <p>
                                You no longer have to plan your workouts around
                                a gym's schedule. Enjoy 24/7 access to premium
                                quality gym equipment.
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="bookACall">
                <div className="wrapper">
                    <div>
                        <h2>Want to learn more?</h2>

                        <p>
                            Book an in-person tour of Toronto's Premier Private
                            Training Gym & Coworking Space. Location: 577
                            Wellington St W
                        </p>

                        <button>
                            <a href="https://calendly.com/pltfrm-fit/30mintour?month=2024-09">
                                Book a Tour
                            </a>
                        </button>
                    </div>
                </div>
            </section>

            <section className="facilityFeaturesSection">
                <div className="wrapper">
                    <h2>
                        Private Training & Gym &{" "}
                        <span className="titleImportant">Coworking Space</span>
                    </h2>
                    <p>
                        PLTFRM is located at 577 Wellington St W in the heart of
                        Toronto’s downtown King West area. We are easily
                        accessible by TTC and offer limited onsite parking with
                        nearby public parking options. This is Toronto's newest
                        hotspot for fitness enthusiasts.
                    </p>
                    <ul className="facilityFeatureList">
                        <li>
                            <img
                                src={weight}
                                alt="weight icon"
                                className="graphic"
                            />
                            <h3>Top Quality Equipment</h3>
                        </li>
                        <li>
                            <img
                                src={camera}
                                alt="camera icon"
                                className="graphic"
                            />
                            <h3>Group Fitness Classes</h3>
                        </li>
                        <li>
                            <img
                                src={showerhead}
                                alt="Showerhead icon"
                                className="graphic"
                            />
                            <h3>Showers & Changing Rooms</h3>
                        </li>
                        <li>
                            <img
                                src={laptop}
                                alt="Laptop Icon"
                                className="graphic"
                            />
                            <h3>Consultation & Coworking Rooms</h3>
                        </li>
                        <li>
                            <img
                                src={wifi}
                                alt="Wifi icon"
                                className="graphic"
                            />
                            <h3>Free Wifi</h3>
                        </li>
                        <li>
                            <img
                                src={onlineBooking}
                                alt="Online booking icon"
                                className="graphic"
                            />
                            <h3>Online Booking & Payments</h3>
                        </li>
                        <li>
                            <img
                                src={clock}
                                alt="clock icon"
                                className="graphic"
                            />
                            <h3>Open 24/7</h3>
                        </li>
                        <li>
                            <img
                                src={people}
                                alt="People hugging icon"
                                className="graphic"
                            />
                            <h3>
                                Exclusive Access to a Growing Fitness Community
                            </h3>
                        </li>
                    </ul>
                    <button>
                        <Link to="/memberships">Join PLTFRM</Link>
                    </button>
                </div>
            </section>

            <Form />
        </>
    );
}
export default HomePage