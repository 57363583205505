import "../styling/Form.scss";
import image4 from "../assets/pltfrmImage4.jpg";

import { useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import  ActiveCampaignForm from "../components/ActiveCampaignForms/ActiveCampaignForm"

const Form = ()=>{
    const location = useLocation()
    const elementRef = useRef(null)

    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(function(){
    
        if (location.pathname === "/form"){
        elementRef.current.scrollIntoView({
            behavior: "smooth",
        })
        }
        setHasLoaded(true)
    },[location])


    return (
        <section className="formSection" id="formSection" ref={elementRef}>
            <div className="wrapper">
                <div className="signUpForm">
                    <img src={image4} alt="" className="formImage" />
                    <div className="signUpText">
                        <div className="signUpHeader">
                            <h3>
                                Join our mailing list to stay up to date with
                                all things <div className="logoName"></div>
                            </h3>
                        </div>
                        <p>
                            When you sign up you will get priority access to all offers and insider access to all PLTFRM updates.
                        </p>
                    </div>
                </div>

                <ActiveCampaignForm form={hasLoaded} />
            </div>
        </section>
    );
}

export default Form