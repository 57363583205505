import React, {useEffect, useState, useRef} from 'react'
import WorkOutForm from "../components/ActiveCampaignForms/ActiveCampaignOurTrainersForm";

import "../styling/WorkOutFormPage.scss";

import { useLocation } from "react-router-dom";


function WorkOutFormPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const location = useLocation();
    const elementRef = useRef(null);

    useEffect(
        function () {
            if (location.pathname === "/ourtrainers") {
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
            setHasLoaded(true);
        },
        [location]
    );
  return (
    <div>
        <WorkOutForm showForm={hasLoaded}/>
    </div>
  )
}

export default WorkOutFormPage