import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState } from "react";
import { useEffect } from "react";
import "../../styling/ClassesPage.scss"

const WellnessLiving = (props) => {
    const [loadWidget, setLoadWidget] = useState(false);
    useEffect(() => {
        if (props.load === false) {
            setLoadWidget(true);
        } else {
            setLoadWidget(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reloadPage =()=>{
        window.location.reload()
    }

    return (
        
        <div className="wellnessWidgetContainer">
            {loadWidget ? (
                <>
                    <div
                        class="wl-widget"
                        data="k_skin=240898&amp;k_business=416410"
                    ></div>
                    <HelmetProvider>
                        <Helmet>
                            <script
                                src="https://www.wellnessliving.com/rs/skin-widget-static.js"
                                type="text/javascript"
                            ></script>
                        </Helmet>
                    </HelmetProvider>
                    <div className="widgetNameContainer">
                        <a
                            href="https://www.wellnessliving.com/fitness/"
                            rel="nofollow"
                            className="widgetATag"
                        >
                            <img
                                src="https://d1v4s90m0bk5bo.cloudfront.net/E/S.png"
                                alt="Fitness management software"
                                height="39"
                                width="267"
                                className="widgetImgTag"
                            />
                        </a>
                        <br clear="both" />

                    </div>
                </>
            ) : (
                <div className="widgetError">
                    <p>Oops something strange happened, please reload</p>
                    <button onClick={reloadPage}>Reload</button>
                </div>
            )}
        </div>
        
    );
};

export default WellnessLiving;
